
import {
	IonButton,
	IonItem,
	IonLabel,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonList,
	IonListHeader,
	IonIcon,
	IonChip,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { Player, Match } from '@/interfaces';

export default defineComponent({
	name: 'AddGroup',
	data() {
		return {
			close,
			matchTypes: [
				{
					name: '5-set',
					value: 3,
				},
				// {
				// 	name: '7-set',
				// 	value: 4,
				// },
			],
			name: '',
			matchType: 3,
			playerName: '',
			playerClub: '',
			players: [] as any[],
		};
	},
	methods: {
		addPlayer(e: any) {
			e.preventDefault();
			const newPlayer = {
				id: Math.floor(Math.random() * 100000),
				name: this.playerName,
				club: this.playerClub,
			};
			this.players.push(newPlayer);
			this.playerName = '';
			this.playerClub = '';
		},
		removePlayer(id: number) {
			this.players = this.players.filter((p) => p.id !== id);
		},
		submitForm() {
			if (this.players.length < 2) {
				alert('Minst två spelare!');
				return;
			}
			const players: Player[] = JSON.parse(JSON.stringify(this.players));
			const matches: Match[] = players
				.map(({ id }) => id)
				.flatMap((id1, i) =>
					players.slice(i + 1).map(({ id: id2 }) => ({
						id: Math.floor(Math.random() * 100000),
						games: [],
						players: [id1, id2],
					}))
				);

			const newGroup = {
				id: Math.floor(Math.random() * 100000),
				name: this.name,
				matchType: this.matchType,
				nrOfMatches: (players.length * (players.length - 1)) / 2,
				players,
				matches,
			};
			this.$store.dispatch('addGroup', newGroup);
			this.$router.back();
		},
	},
	components: {
		IonButton,
		IonInput,
		IonItem,
		IonLabel,
		IonSelect,
		IonSelectOption,
		IonList,
		IonListHeader,
		IonChip,
		IonIcon,
	},
});
